// Doctor infomation page. Page for patient to view doctor information
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import HospitalIcon from '../../img/hospital.png';
import StudyIcon from '../../img/graduation-cap.png';
import PhoneIcon from '../../img/phone.png';
import Medicalreport from '../../img/medical-report.png';
import VideoCameraIcon from '../../img/video-camera.png';
import Axios from 'axios';
import Spinner from '../../components/Spinner';

const DoctorInfo = ({match}) => {
  const history = useHistory();
  const [fetchFail, setFetchFail] = useState(false); // condition of fectching doctor data

  // doctor
  const [doctor, setDoctor] = useState({
    data: null,
    isPending: true,
    error: null,
    called: false
  });

  useEffect(() => {
    fetchDoctor(setDoctor, match.params.id); // get doctor
  }, [setDoctor,match.params.id]);

  // token
  const config = {
    headers: {
      'x-acess-token': localStorage.getItem('token'),
    },
  };

  if (fetchFail) {
    return (
      <>
        <div className='flex justify-center items-center mt-52'>
          <h1 className="font-fontPro text-4xl text-gray-700">This doctor is offline or in another call</h1>
        </div>
      </>
    );
  }

    // call doctor
    const callDoctor = () => {
      setFetchFail(true);
    };

  // color of specialization
  const colorDoc = (doctor) => {
    let color = '';
    switch (doctor.data.specialization.specialization) {
      case 'Physician':
        color = 'blue-300';
        break;
      case 'Otolaryngologist':
        color = 'green-300';
        break;
      case 'Pediatricians':
        color = 'yellow-300';
        break;
      case 'Gynecologist':
        color = 'pink-300';
        break;
      case 'Cardiologist':
        color = 'red-300';
        break;
      case 'Urologists':
        color = 'indigo-300';
        break;
      case 'Orthopedist':
        color = 'purple-300';
        break;
      case 'Psychiatrist':
        color = 'blue-200';
        break;
      case 'Surgeon':
        color = 'green-200';
        break;
      case 'Dermatologist':
        color = 'yellow-200';
        break;
      case 'Neurologist':
        color = 'pink-200';
        break;
      case 'Dentist':
        color = 'red-200';
        break;
      case 'Ophthalmologist':
        color = 'indigo-200';
        break;
      default:
        color = 'grap-200';
        break;
      // code block
    }
    return color;
  };

  if (doctor.isPending) {
    return (
      <Spinner/>
    );
  } else {
  return (
    <div className='font-fontPro'>
      <div className='p-3'>
        <button className='text-base text-gray-700' onClick={history.goBack}>
          <i className='fas fa-chevron-left text-gray-700'></i>
          <span> Back</span>
        </button>
      </div>
      <div className='flex justify-center px-20 py-2 mb-2'>
        <div className='w-1/3 bg-gray-200 py-10'>
          <div className='w-full px-20 py-5'>
            <img
              className='object-cover object-center rounded-full w-full border-solid border-white border-4'
              src={doctor.data.photo}
              alt='docpic'
            />
          </div>
          <div className='text-center text-xl p-5'>
            <h1> {doctor.data.name}</h1>
          </div>
          <div className='flex justify-center'>
            <h1
              className={`text-sm font-medium text-gray-700 mb-3 bg-${colorDoc(
                doctor
              )}  inline-block px-1 rounded-md text-center justify-center`}
            >
              {doctor.data.specialization.specialization}
            </h1>
          </div>
        </div>
        <div
          className='bg-white shadow-xl w-2/3 overflow-hidden rounded-lg'
          style={{maxWidth: 1000}}
        >
          <div className='w-full py-10 px-5 md:px-10'>
            <div className='flex py-2'>
              <img className='h-10 w-10' src={HospitalIcon} alt='Hospital' />
              <div id='body' className='flex flex-col ml-5'>
                <h1 className='p-2 text-xl'>Hospital : {doctor.data.hospital}</h1>
              </div>
            </div>
            <div className='flex py-2 mt-2'>
              <img className='h-10 w-10' src={StudyIcon} alt='Education' />
              <div id='body' className='flex flex-col ml-5'>
                <h1 className='p-2 text-xl'>background : {doctor.data.background}</h1>
              </div>
            </div>
            <div className='flex py-2'>
              <img className='h-10 w-10' src={PhoneIcon} alt='Phone' />
              <div id='body' className='flex flex-col ml-5'>
                <h1 className='p-2 text-xl'>
                  Phone number : {doctor.data.phone}
                  <p />
                </h1>
              </div>
            </div>
            <div className='flex py-2'>
              <img
                className='h-10 w-10'
                src={Medicalreport}
                alt='Medical record'
              />
              <div id='body' className='flex flex-col ml-5'>
                <h1 className='p-2 text-xl'>Specialization Detail : </h1>
              </div>
            </div>
            <div>
              <p className='ml-16 p-1 text-l break-words'>
                {doctor.data.specializationDetail}
              </p>
            </div>
            <div className='flex justify-center'>
              <button
                onClick={callDoctor}
                className='bg-green-400 hover:bg-green-500 font-bold py-2 px-4 mt-10 rounded inline-flex'
              >
                <img className='h-10 w-10' src={VideoCameraIcon} alt='' />
                <div className='flex flex-col ml-5'>
                  <h1 className='py-1.5 text-xl text-white -ml-3'>Call</h1>
                </div>
              </button>
            </div>
            {doctor.called && <div>Doctor in unavailable now</div>}
          </div>
        </div>
      </div>
    </div>
  );
};
};

const fetchDoctor = (setDoctor, id) => {
  const fetchData = async () => {
    try {
      let res = await Axios.get(`/api/doctor/${id}`, {
        headers: {
          'x-acess-token': localStorage.getItem('token'),
        },
      });
      let data = res.data.data;

      setDoctor({
        data: data,
        isPending: false,
        error: null,
      });
    } catch (error) {
      setDoctor({
        data: null,
        isPending: false,
        error: error,
      });
    }
  };
  fetchData();
};

export default DoctorInfo;
