// Patient infomation page. Page for patient to view patient information
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import HospitalIcon from '../../img/hospital.png';
import StudyIcon from '../../img/medical-report-white.png';
import PhoneIcon from '../../img/phone.png';
import Medicalreport from '../../img/medical-report.png';
import Axios from 'axios';
import Spinner from '../../components/Spinner';

const PatientInfo = ({match}) => {
  const history = useHistory();
  const [fetchFail, setFetchFail] = useState(false); // condition of fectching patient data

  // patient
  const [patient, setPatient] = useState({
    data: null,
    isPending: true,
    error: null,
    called: false
  });

  useEffect(() => {
    fetchPatient(setPatient, match.params.id); // get patient
  }, [setPatient,match.params.id]);

  // token
  const config = {
    headers: {
      'x-acess-token': localStorage.getItem('token'),
    },
  };

  if (fetchFail) {
    return (
      <>
        <div className='flex justify-center items-center mt-52'>
          <h1 className="font-fontPro text-4xl text-gray-700">This patient is deleted</h1>
        </div>
      </>
    );
  }


  if (patient.isPending) {
    return (
      <Spinner/>
    );
  } else {
  return (
    <div className='font-fontPro'>
      <div className='p-3'>
        <button className='text-base text-gray-700' onClick={history.goBack}>
          <i className='fas fa-chevron-left text-gray-700'></i>
          <span> Back</span>
        </button>
      </div>
      <div className='flex justify-center px-20 py-2 mb-2'>
        <div className='w-1/3 bg-gray-200 py-10'>
          <div className='w-full px-20 py-5'>
            <img
              className='object-cover object-center rounded-full w-full border-solid border-white border-4'
              src={'/api/public/img/doctor/patient.png'}
              alt='docpic'
            />
          </div>
          <div className='text-center text-xl p-5'>
            <h1> {patient.data.name}</h1>
          </div>
          <div className='flex justify-center'>
            <h1
              className={`text-sm font-medium text-gray-700 mb-3 inline-block px-1 rounded-md text-center justify-center`}
            >
              {patient.data.birthdate && patient.data.birthdate.split('T')[0]}
            </h1>
          </div>
        </div>
        <div
          className='bg-white shadow-xl w-2/3 overflow-hidden rounded-lg'
          style={{maxWidth: 1000}}
        >
          <div className='w-full py-10 px-5 md:px-10'>
            <div className='flex py-2'>
              <img className='h-10 w-10' src={StudyIcon} alt='Hospital' />
              <div id='body' className='flex flex-col ml-5'>
                <h1 className='p-2 text-xl'>ID card : {patient.data.IDcard}</h1>
              </div>
            </div>
            <div className='flex py-2 mt-2'>
              <img className='h-10 w-10' src={StudyIcon} alt='Education' />
              <div id='body' className='flex flex-col ml-5'>
                <h1 className='p-2 text-xl'>Email : {patient.data.email}</h1>
              </div>
            </div>
            <div className='flex py-2'>
              <img className='h-10 w-10' src={PhoneIcon} alt='Phone' />
              <div id='body' className='flex flex-col ml-5'>
                <h1 className='p-2 text-xl'>
                  Phone number : {patient.data.phone}
                  <p />
                </h1>
              </div>
            </div>
            <div className='flex py-2'>
              <img
                className='h-10 w-10'
                src={Medicalreport}
                alt='Medical record'
              />
              <div id='body' className='flex flex-col ml-5'>
                <h1 className='p-2 text-xl'>Alergy : </h1>
              </div>
            </div>
            <div>
              <p className='ml-16 p-1 text-l break-words' 
                  dangerouslySetInnerHTML={{ __html: patient.data.allergy }}>
              
              </p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};
};

const fetchPatient = (setPatient, id) => {
  const fetchData = async () => {
    try {
      let res = await Axios.get(`/api/patient/${id}`, {
        headers: {
          'x-acess-token': localStorage.getItem('token'),
        },
      });
      let data = res.data.data;

      setPatient({
        data: data,
        isPending: false,
        error: null,
      });
    } catch (error) {
      setPatient({
        data: null,
        isPending: false,
        error: error,
      });
    }
  };
  fetchData();
};

export default PatientInfo;
